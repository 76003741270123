import { Component, OnInit } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { NgZone } from '@angular/core';
import { ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import * as moment from 'moment';
import { SITE_SETTINGS, TIME } from 'src/app/settings';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { CARS } from 'src/app/cars';
import { MatStepper } from '@angular/material/stepper';
import { TaxiService } from 'src/app/taxi.service';

@Component({
  selector: 'app-outstation',
  templateUrl: './outstation.component.html',
  styleUrls: ['./outstation.component.css']
})
export class OutstationComponent implements OnInit {

  @ViewChild('origin', { read: ElementRef }) originElementRef: ElementRef;
  @ViewChild('destination', { read: ElementRef }) destinationElementRef: ElementRef;
  @ViewChild('stepper') stepper: MatStepper;

  latitude: number = 11.127123;
  longitude: number = 78.656891;
  zoom: number = 5;
  showDirection = false;
  origin;
  destination;
  originCityName;
  destinationCityName;
  routeDetails;
  submitted = false;
  rideDetails;
  settings = SITE_SETTINGS;
  minDate = new Date();
  times = TIME;
  selectedDateByName = moment(new Date()).format('ddd');
  pickupPlace;
  dropPlace;
  showLocationError = false;
  cars;
  firstFormGroup: FormGroup;
  showCarsList = false;
  selectedCar;
  requestRide;
  days;
  estimation;
  bookingDetails;
  show = false;

  constructor(
    private fb: FormBuilder,
    private ngZone: NgZone,
    private mapsAPILoader: MapsAPILoader,
    private dateAdapter: DateAdapter<Date>,
    private http: HttpClient,
    private taxiService: TaxiService,

  ) {
    this.dateAdapter.setLocale('en-GB');
  }

  ngOnInit() {

    this.taxiService.tariff.subscribe(res => {
      if(res) {
        this.cars = res;
      }
    })
    this.mapsAPILoader.load().then(() => {
      const origin = this.originElementRef?.nativeElement;
      const destination = this.destinationElementRef?.nativeElement;
      this.addMapEventListener(origin, 'origin');
      this.addMapEventListener(destination, 'destination');
    });

    this.firstFormGroup = this.fb.group({
      tripType: ['One Way', Validators.required],
      name: ['', Validators.required],
      phone: ['', [
        Validators.required,
        Validators.pattern(/^[0]?[6789]\d{9}$/)
      ]],
      pickupDate: [new Date, Validators.required],
      pickupTime: ['12:00 AM', Validators.required],
      returnDate: [new Date],
      selectedCar: ['SEDAN', Validators.required],
      origin: ['', Validators.required],
      destination: ['', Validators.required],
    });
  }

  get bookingFormValidation() { return this.firstFormGroup.controls; }

  requestAride() {

    this.submitted = true;

    if (this.firstFormGroup.invalid) {
      return;
    }

    if (!this.origin || !this.destination) {
      alert('Enter valid Pickup or Drop Address');
      return
    }

    this.rideDetails = {
      ...this.firstFormGroup.value,
      ...this.routeDetails
    }

    this.getEstimation();
  }

  checkDate(start, end) {
    let startDate = moment(start, 'DD-MM-YYYYTHH:mm:ss');
    let returnDate = moment(end, 'DD-MM-YYYYTHH:mm:ss');
    return startDate.isSameOrBefore(returnDate);
  }


  addMapEventListener(input, type) {

    const autocomplete = new google.maps.places.Autocomplete(input);
    autocomplete.setComponentRestrictions({
      country: ["in"],
    });
    autocomplete.addListener("place_changed", () => {

      this.ngZone.run(() => {
        let place: google.maps.places.PlaceResult = autocomplete.getPlace();

        if (place.geometry === undefined || place.geometry === null) {
          return;
        }

        (type === 'origin') ? this.origin = place.formatted_address : this.destination = place.formatted_address;
        (type === 'origin') ? this.originCityName = place.name : this.destinationCityName = place.name;

        this.firstFormGroup.get('origin').setValue(this.origin);
        this.firstFormGroup.get('destination').setValue(this.destination);

        if (this.origin && this.destination) {
          this.showDirection = true;
          this.pickupPlace = this.origin;
          this.dropPlace = this.destination;
          (this.selectedCar) ? this.requestRide = true : this.requestRide = false;
        }

        this.zoom = 12;
      });
    });
  }

  onResponses(event) {

    this.routeDetails = {
      'origin': this.origin,
      'destination': this.destination,
      'originCityName': this.originCityName,
      'destinationCityName': this.destinationCityName,
      'totalDistance': event.routes[0].legs[0].distance.text.replace(/[^0-9.-]+/g, ""),
      'totalDuration': event.routes[0].legs[0].duration.text
    }
  }

  getEstimation() {

    this.selectedCar = this.cars.filter(car => car.carType.toLowerCase() === this.rideDetails.selectedCar.toLowerCase())[0];


    if (this.rideDetails.tripType === 'One Way') {
      (this.rideDetails.totalDistance <= 130) ? this.rideDetails.totalDistance = 130 : this.rideDetails.totalDistance;
    } else {
      let startDate = moment(moment(this.rideDetails.pickupDate).format('DD/MM/YYYY'), "DD.MM.YYYY");
      let returnDate = moment(moment(this.rideDetails.returnDate).format('DD/MM/YYYY'), "DD.MM.YYYY");
      this.days = returnDate.diff(startDate, 'days') + 1;

      (this.rideDetails.totalDistance * 2 <= 250) ? this.rideDetails.totalDistance = this.days * 250 : (this.rideDetails.totalDistance * 2 > 250 * this.days) ? this.rideDetails.totalDistance = this.rideDetails.totalDistance * 2 : this.rideDetails.totalDistance = 250 * this.days;
    }

    this.estimation = {
      tripEstimation:
        (this.rideDetails.tripType === 'One Way') ? this.rideDetails.totalDistance * +this.selectedCar.oneway + +this.selectedCar.onewayDriverFee :
          this.selectedCar.roundtrip * this.rideDetails.totalDistance + +this.selectedCar.roundTripDriverFee * this.days,
      ratePerKM: (this.rideDetails.tripType === 'One Way') ? this.selectedCar.oneway : this.selectedCar.roundtrip,
      driverBatta: (this.rideDetails.tripType === 'One Way') ? this.selectedCar.onewayDriverFee : this.selectedCar.roundTripDriverFee,
      baseFare: (this.rideDetails.tripType === 'One Way') ? this.rideDetails.totalDistance * +this.selectedCar.oneway : this.rideDetails.totalDistance * +this.selectedCar.roundtrip,
      tripType: this.rideDetails.tripType,
      carType: this.selectedCar.carType,
      distance: this.rideDetails.totalDistance
    }

    this.bookingDetails = {
      ...this.rideDetails,
      ...this.estimation,
      bookingId: Math.random().toString(36).substr(2, 9).toLocaleUpperCase(),
    }


    this.bookNow();

  }



  bookNow() {
    this.bookingDetails = {
      ...this.firstFormGroup.value,
      ...this.routeDetails,
      ...this.estimation,
      bookingId: Math.random().toString(36).substr(2, 9).toLocaleUpperCase()
    }

    let bookingDetails = {
      'bookingId': this.bookingDetails.bookingId,
      'name': this.bookingDetails.name,
      'phoneNo': this.bookingDetails.phone,
      'origin': this.bookingDetails.origin,
      'originCityName': this.bookingDetails.originCityName,
      'destination': this.bookingDetails.destination,
      'destinationCityName': this.bookingDetails.destinationCityName,
      'distance': this.bookingDetails.distance,
      'carType': this.bookingDetails.carType,
      'bookedDate': moment(this.bookingDetails.pickupDate).format('MM/DD/YYYY'),
      'bookedTime': this.bookingDetails.pickupTime,
      'estimate': this.bookingDetails.tripEstimation,
      'duration': this.bookingDetails.totalDuration,
      'tripType': this.bookingDetails.tripType,
      'driverBatta': this.bookingDetails.driverBatta,
      'ratePerKM': this.bookingDetails.ratePerKM,
      'returnDate': (this.bookingDetails.tripType === 'Round Trip') ? moment(this.bookingDetails.returnDate).format('MM/DD/YYYY') : 'NA',
      'adminEmail': this.settings.enquiryEmail,
      'adminPhone': this.settings.phoneNo,
      'adminWhatsapp': this.settings.whatsapp,
      'websiteAddress': this.settings.websiteAddress,
      'siteName': this.settings.siteName,
      'siteInfo': this.settings.siteInfo,
      'adminEmailSubject': `Website Enquiry From ${this.bookingDetails.name}`,
      'bannerColor': this.settings.bannerColor,
      'logoURL': this.settings.logoURL,
      tollCost: (this.bookingDetails.tollCost) ? this.bookingDetails.tollCost : 0,
    }


    let smsMessage = `
    Website Enquiry \r\n
    Your Booking Details:\r\n
    Booking ID: ${bookingDetails.bookingId}\r\n
    Name: ${bookingDetails.name}\r\n
    Mobile Number: ${bookingDetails.phoneNo}\r\n
    Pickup Location: ${bookingDetails.origin}\r\n
    Drop Location: ${bookingDetails.destination} \r\n
    Pickup Date/Time: ${bookingDetails.bookedDate} ${bookingDetails.bookedTime} \r\n
    Trip Type: ${bookingDetails.tripType} \r\n
Car Type:  ${bookingDetails.carType} \r\n
    Total KM: ${bookingDetails.distance} KM\r\n
    Total Trip Fare: ${bookingDetails.estimate} ₹\r\n
    Rate Per KM: ${bookingDetails.ratePerKM} ₹\r\n
    Driver Batta: ${bookingDetails.driverBatta} ₹\r\n
    Toll, parking, permit extra \r\n 
    For any questions please contact ${this.settings.phoneNo} \r\n 
    https://${this.settings.websiteAddress}`;

    let telegramBody = {
      message: smsMessage,
      smsKey: this.settings.smsKey,
      tKey: this.settings.tKey,
      tGroup: this.settings.tGroup
    }

    this.http.post(this.settings.emailAPI, bookingDetails).subscribe(res => console.log(res));

    // this.scrollToTop();
    this.show = true;

    setTimeout(() => {
      this.stepper.next();
    }, 250);
  }

  confirm() {
    let bookingDetails = {
      'bookingId': this.bookingDetails.bookingId,
      'name': this.bookingDetails.name,
      'phoneNo': this.bookingDetails.phone,
      'email': this.bookingDetails.email,
      'origin': this.bookingDetails.origin,
      'originCityName': this.bookingDetails.originCityName,
      'destination': this.bookingDetails.destination,
      'destinationCityName': this.bookingDetails.destinationCityName,
      'distance': this.bookingDetails.distance,
      'carType': this.bookingDetails.carType,
      'bookedDate': moment(this.bookingDetails.pickupDate).format('MM/DD/YYYY'),
      'bookedTime': this.bookingDetails.pickupTime,
      'estimate': this.bookingDetails.tripEstimation,
      'duration': this.bookingDetails.totalDuration,
      'tripType': this.bookingDetails.tripType,
      'driverBatta': this.bookingDetails.driverBatta,
      'ratePerKM': this.bookingDetails.ratePerKM,
      'returnDate': (this.bookingDetails.tripType === 'Round Trip') ? moment(this.bookingDetails.returnDate).format('MM/DD/YYYY') : 'NA',
      'adminEmail': this.settings.enquiryEmail,
      'adminPhone': this.settings.phoneNo,
      'adminWhatsapp': this.settings.whatsapp,
      'websiteAddress': this.settings.websiteAddress,
      'siteName': this.settings.siteName,
      'siteInfo': this.settings.siteInfo,
      'adminEmailSubject': `Confirmed Booking From ${this.bookingDetails.name}`,
      'bannerColor': this.settings.bannerColor,
      'sendCustomerEmail': true,
      'logoURL': this.settings.logoURL,
      tollCost: (this.bookingDetails.tollCost) ? this.bookingDetails.tollCost : 0,
      tollStatus: 'EXTRA',
      toll:'EXTRA',
    }

    let returnDate = `${(bookingDetails.tripType === 'One Way') ? '' : 'Return Date:' + bookingDetails.returnDate}`

    let smsMessage = `
      Thanks for Choosing ${this.settings.siteName}.
      Your Booking Details:\r\n
      Booking ID: ${bookingDetails.bookingId}\r\n
      Name: ${bookingDetails.name}\r\n
      Mobile Number: ${bookingDetails.phoneNo}\r\n
      Pickup Location: ${bookingDetails.origin}\r\n
      Drop Location: ${bookingDetails.destination} \r\n
      Pickup Date/Time: ${bookingDetails.bookedDate} ${bookingDetails.bookedTime} \r\n
      ${returnDate} \r\n
      Trip Type: ${bookingDetails.tripType} \r\n
      Total KM: ${bookingDetails.distance} KM\r\n
      Total Trip Fare: ${bookingDetails.estimate} ₹\r\n
      Rate Per KM: ${bookingDetails.ratePerKM} ₹\r\n
      Driver Batta: Included \r\n
      Toll, Parking, permit extra  \r\n
      For any questions please contact ${this.settings.phoneNo} \r\n 
      https://${this.settings.websiteAddress}`


    let whatsappMessage = window.encodeURIComponent(smsMessage);

    let newSMS = `
      Hi ${bookingDetails.name}, Thanks for booking a Taxi with ${bookingDetails.siteName}. Your booking was confirmed our representative will call in 10-15 mins. For any queries please contact ${this.settings.phoneNo}.
  Happy Journey.
      `

    let smsBody = {
      message: newSMS,
      numbers: `${bookingDetails.phoneNo}`,
      smsKey: this.settings.smsKey
    }

    let telegramBody = {
      message: smsMessage,
      smsKey: this.settings.smsKey,
      tKey: this.settings.tKey,
      tGroup: this.settings.tGroup
    }

    if (this.settings.isTelegramEnabled) {
      this.http.post(this.settings.telegramAPI, telegramBody).subscribe(res => console.log(res));
    }

    if (this.settings.isSMSEnabled) {
      this.http.post(this.settings.smsAPI, smsBody).subscribe(res => console.log(res));
    }

    this.http.post(this.settings.emailAPI, bookingDetails).subscribe(res => console.log(res));

    let whatsupText = `https://api.whatsapp.com/send?phone=${this.settings.whatsapp}&text=${whatsappMessage}`;
    window.open(whatsupText, "_blank");
  }

  home() {

  }


}

