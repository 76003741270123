<div id="topid">


    <div class="outer app-hide">
        <div class="inner" [ngClass]="{'actv_nw': tripType === 'Outstation'}">
            <a (click)="tripType ='Outstation'">
                <i class="cb_n_icn"></i>
                <span class="icn_ttl fw700">Outstation</span>
            </a>
        </div>

        <div class="inner" [ngClass]="{'actv_nw': tripType === 'Rental'}">
            <a (click)="tripType ='Rental'">
                <i class="fli_n_icn"></i>
                <span class="icn_ttl">Rental</span>
            </a>
        </div>


    </div>
</div>
<div id="dvsearch" style="display:block;">
    <div class="cont_pnl">
        <div class="pd20_n">

            <div class="clr"></div>

            <ng-container *ngIf="tripType === 'Outstation'">
                <app-outstation></app-outstation>
            </ng-container>
            <ng-container *ngIf="tripType === 'Rental'">
                <app-rental></app-rental>
            </ng-container>
        </div>
    </div>
</div>