<a routerLink="/">
    <h4>
        <img src="assets/left-arrow.png">Contact Us
    </h4>
</a>

<section class="ttm-row conatact-section clearfix">
    <div class="container">
        <!-- row -->
        <div class="row">

            <div class="col-xl-7">
                <div class="padding_left15 res-1199-padding_left0 padding_top20 res-1199-padding_top40">
                    <!-- section title -->
                    <div class="section-title">
                        <div class="title-header">
                            <h3>CONTACT US</h3>
                            <h2 class="title">Get In <b>Touch!</b></h2>
                        </div>
                        <div class="title-desc">
                            <p>We provide a friendly service with good hospitality. Kindly contact us for a wonderful travel experience.</p>
                            <p>
                                We always guarantee a memorable and happy trip. We have well maintained cars with friendly drivers. We are awaiting to serve you.


                            </p>
                        </div>
                    </div>
                    <!-- section title end -->
                    <h2 class="fs-24 padding_top10">Head Office</h2>
                    <div class="ttm-horizontal_sep width-100 margin_top20 margin_bottom5"></div>
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <!--featured-icon-box-->
                            <div class="featured-icon-box icon-align-before-content">
                                <div class="featured-icon">
                                    <div class="ttm-icon ttm-icon_element-fill ttm-icon_element-color-grey ttm-icon_element-size-md ttm-icon_element-style-rounded">
                                        <i class="themifyicon ti-location-pin ttm-textcolor-skincolor"></i>
                                    </div>
                                </div>
                                <div class="featured-content">
                                    <div class="featured-title">
                                        <h3 class="margin_bottom0 fs-18">Address</h3>
                                    </div>
                                    <div class="featured-desc">3/42 ganthi nagar 2nd Street palavakkam Chennai 600041

                                    </div>
                                </div>
                            </div>
                            <!-- featured-icon-box end-->
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <!--featured-icon-box-->
                            <div class="featured-icon-box icon-align-before-content">
                                <div class="featured-icon">
                                    <div class="ttm-icon ttm-icon_element-fill ttm-icon_element-color-grey ttm-icon_element-size-md ttm-icon_element-style-rounded">
                                        <i class="themifyicon ti-email ttm-textcolor-skincolor"></i>
                                    </div>
                                </div>
                                <div class="featured-content">
                                    <div class="featured-title">
                                        <h3 class="margin_bottom0 fs-18">Call Us / Email</h3>
                                    </div>
                                    <div class="featured-desc">{{settings.phoneNo}}<br>{{settings.enquiryEmail}}</div>
                                </div>
                            </div>
                            <!-- featured-icon-box end-->
                        </div>
                    </div>

                </div>
            </div>
            <!-- <div class="col-xl-5">
                <iframe src="https://maps.google.com/maps?q=chennai&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"
                    width="100%" height="558"></iframe>
            </div> -->
        </div>
        <!-- row end -->
    </div>
</section>