<mat-horizontal-stepper #stepper>



    <mat-step [stepControl]="firstFormGroup" [editable]="true">
        <form [formGroup]="firstFormGroup">

            <div class="mgtb6">
                <mat-radio-group style="
                                              
                display: inline-block;
            " aria-label="Select an option" formControlName="tripType">
                    <mat-radio-button value="One Way" style="margin-right: 10px;">One Way
                    </mat-radio-button>
                    <mat-radio-button value="Round Trip">Round Trip</mat-radio-button>
                </mat-radio-group>
            </div>

            <div class="wid100 fl">
                <div class="pd10_n fl">
                    <mat-error *ngIf="submitted && bookingFormValidation['name'].errors">
                        Name is <strong>required</strong>
                    </mat-error>
                    <mat-error *ngIf="submitted && bookingFormValidation['phone'].errors">
                        Mobile number is <strong>required</strong>
                    </mat-error>

                    <div class="row">
                        <div class="col">
                            <p class="inpttl">Enter Name</p>
                            <input type="text" placeholder="Full name" formControlName="name" class="hl-input">
                        </div>
                        <div class="col">
                            <p class="inpttl">Mobile Number</p>
                            <input class="hl-input" type="number" formControlName="phone" placeholder="Mobile Number"
                                required>
                        </div>

                    </div>

                </div>
            </div>

            <div class="clr"></div>

            <div class="clr"></div>

            <div class="pd10_n mgt13">
                <div class="wid10 ng-scope" ng-if="SearchDetail.TravelType!='hourly'">
                    <div class="w_circle"></div>
                    <i class="des_icn"></i>
                </div>
                <div class="wid87">
                    <div class="in_box3 m_rel">
                        <p class="inpttl">Pick-up location</p>
                        <input type="text" placeholder="Enter Pickup location" #origin class="hl-input"
                            formControlName="origin">
                    </div>
                    <div class="in_box2 ng-scope" id="dvReturnadd">
                        <p class="inpttl" id="">Drop location</p>
                        <input type="text" placeholder="Enter Drop location" id="spndestname" class="hl-input"
                            #destination formControlName="destination">
                    </div>
                </div>



            </div>
            <div class="wid100 fl date-wrap">
                <div class="pd10_n fl mgt13">
                    <div class="row">
                        <div class="col">
                            <p class="inpttl">Pick-up Date</p>
                            <input formControlName="pickupDate" placeholder="Pickup date"
                                formControlName="pickupDate" [min]="minDate" (click)="picker.open()"
                                [matDatepicker]="picker" readonly class="hl-input2">
                            <mat-datepicker #picker></mat-datepicker>
                        </div>
                        <div class="col">
                            <p class="inpttl">Select Time</p>
                            <mat-select class="time-select" formControlName="pickupTime" placeholder="Time">
                                <mat-option *ngFor="let time of times" [value]="time">
                                    {{time}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="submitted && bookingFormValidation['pickupTime'].errors">
                                Pickup Time is <strong>required</strong>
                            </mat-error>
                        </div>

                    </div>

                </div>
            </div>


            <ng-container *ngIf="bookingFormValidation['tripType'].value === 'Round Trip'">
                <div class="clr"></div>

                <div class="wid100 fl">
                    <div class="pd10_n fl mgt13">
                        <div class="row">
                            <div class="col">
                                <p class="inpttl">Return Date</p>
                                <input [min]="bookingFormValidation['pickupDate'].value" (focus)="picker1.open()"
                                    [matDatepicker]="picker1" matInput placeholder="Retun date"
                                    formControlName="returnDate" readonly class="hl-input2">
                                <mat-datepicker #picker1></mat-datepicker>
                            </div>
                        </div>

                    </div>
                </div>

            </ng-container>

            <div class="clr"></div>

            <div class="wid100 fl">
                <div class="pd10_n fl mgt13">
                    <div class="row taxi">
                        <div class="col">
                            <p class="inpttl">Select Cab Type</p>
                            <mat-select class="time-select" formControlName="selectedCar" placeholder="Select Cab Type">
                                <mat-option *ngFor="let car of cars" [value]="car.carType">
                                    {{car.carType | uppercase}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="submitted && bookingFormValidation['selectedCar'].errors">
                                Select Valid <strong>Cab</strong>
                            </mat-error>
                        </div>
                    </div>

                </div>
            </div>


            <div class="clr"></div>


            <div class="clr"></div>
            <input name="" value="Search" style="-webkit-appearance: none;" type="button" class="fs_btn"
                (click)="requestAride()">
        </form>
    </mat-step>

    <mat-step>

        <div *ngIf="show">
            <h2 class="text-center" style="
            margin: 20px;
        ">Trip Estimation <span style="color:#5dc5ff ;">Rs.{{bookingDetails.tripEstimation}}
                </span></h2>

            <table class="table table-bordered">
                <tbody>
                    <tr>
                        <td>Total Distance :</td>
                        <td>{{bookingDetails.distance}} KM</td>
                    </tr>

                    <tr>
                        <td>Total Duration</td>
                        <td>{{bookingDetails.totalDuration}}</td>
                    </tr>
                    <tr>
                        <td>Rate Per Km</td>
                        <td>Rs. {{bookingDetails.ratePerKM}}</td>
                    </tr>
                    <tr *ngIf="bookingDetails.tripType === 'Round Trip'">
                        <td>No of Days :</td>
                        <td>{{this.days}}</td>
                    </tr>
                    <tr>
                        <td>Selected Car Type</td>
                        <td>{{bookingDetails.carType}} </td>
                    </tr>

                    <tr>
                        <td>Driver Allowance :</td>
                        <td>INCLUDED</td>
                    </tr>
                </tbody>
            </table>

            <div class="row">

                <div class="service-head-btn">

                    <a class="btn btn-warning" (click)="confirm()"> Confirm
                        Now</a>
                </div>


            </div>
        </div>


    </mat-step>

    <mat-step>
        <div class="success" *ngIf="bookingDetails">
            <div style="text-align: center;">
                <h4>Ride Booked Successfully</h4>
                <img src="https://images.ctfassets.net/509kpi6dw56l/4zLD6wiohRdGrrQtWGD0KO/49149fa4ce096618802c2c2b0fee57a1/success.gif?h=250"
                    alt="" srcset="">
                <p>
                    Hi {{bookingDetails.name}}, Thanks for Choosing {{settings.siteName}}, your booking is confirmed our
                    representative will contact you shortly.
                </p>
                <div style="text-align: center;">
                    <div class="service-head-btn">

                        <a class="btn btn-warning" (click)="home()"> Home</a>
                    </div>
                </div>
            </div>
        </div>
    </mat-step>
</mat-horizontal-stepper>


<agm-map class="hide" [latitude]="latitude" [longitude]="longitude" [zoom]="5">
    <ng-container *ngIf="showDirection">
        <agm-direction [origin]="pickupPlace" [destination]="dropPlace" (onResponse)="onResponses($event)">
        </agm-direction>
    </ng-container>
</agm-map>