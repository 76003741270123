import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MapsAPILoader } from '@agm/core';
import { NgZone } from '@angular/core';
import { ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import * as moment from 'moment';
import { SITE_SETTINGS, TIME } from 'src/app/settings';
import { TaxiService } from 'src/app/taxi.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-rental',
  templateUrl: './rental.component.html',
  styleUrls: ['./rental.component.css']
})
export class RentalComponent implements OnInit {


  @ViewChild('origin', { read: ElementRef }) originElementRef: ElementRef;
  @ViewChild('destination', { read: ElementRef }) destinationElementRef: ElementRef;

  latitude: number = 11.127123;
  longitude: number = 78.656891;
  zoom: number = 5;
  showDirection = false;
  origin;
  destination;
  originCityName;
  destinationCityName;
  routeDetails;
  bookingFormGroup: FormGroup;
  submitted = false;
  rideDetails;
  settings = SITE_SETTINGS;
  minDate = new Date();
  times = TIME;
  selectedDateByName = moment(new Date()).format('ddd');
  pickupPlace;
  dropPlace;
  showLocationError = false;

  rentalPkg = [
    // { value: '1 hrs-10 KM', viewValue: '1 hrs 10 KM' },
    { value: '2 hrs-20 KM', viewValue: '2 hrs 20 KM' },
    // { value: '3 hrs-30 KM', viewValue: '3 hrs 30 KM' },
    { value: '4 hrs-40 KM', viewValue: '4 hrs 40 KM' },
    { value: '5 hrs-50 KM', viewValue: '5 hrs 50 KM' },
    { value: '6 hrs-60 KM', viewValue: '6 hrs 60 KM' },
    // { value: '7 hrs-70 KM', viewValue: '7 hrs 70 KM' },
    { value: '8 hrs-80 KM', viewValue: '8 hrs 80 KM' },
    { value: '9 hrs-90 KM', viewValue: '9 hrs 90 KM' },
    { value: '10 hrs-100 KM', viewValue: '10 hrs 100 KM' },
    // { value: '11 hrs-110 KM', viewValue: '11 hrs 110 KM' },
    // { value: '12 hrs-120 KM', viewValue: '12 hrs 120 KM' },
    // { value: '13 hrs-130 KM', viewValue: '13 hrs 130 KM' },
    // { value: '14 hrs-140 KM', viewValue: '14 hrs 140 KM' },
    // { value: '15 hrs-150 KM', viewValue: '15 hrs 150 KM' },
    // { value: '16 hrs-160 KM', viewValue: '16 hrs 160 KM' },
    // { value: '17 hrs-170 KM', viewValue: '17 hrs 170 KM' },
    // { value: '18 hrs-180 KM', viewValue: '18 hrs 180 KM' },
    // { value: '19 hrs-190 KM', viewValue: '19 hrs 190 KM' },
    // { value: '20 hrs-200 KM', viewValue: '20 hrs 200 KM' },
    // { value: '21 hrs-210 KM', viewValue: '21 hrs 210 KM' },
    // { value: '22 hrs-220 KM', viewValue: '22 hrs 220 KM' },
    // { value: '23 hrs-230 KM', viewValue: '23 hrs 230 KM' },
    // { value: '24 hrs-240 KM', viewValue: '24 hrs 240 KM' }
  ];

  rentalCity = [
    { value: 'Chennai', viewValue: 'Chennai' },
    // { value: 'Bangalore', viewValue: 'Bangalore' },
    // { value: 'Trichy', viewValue: 'Trichy' },
    // { value: 'Madurai', viewValue: 'Madurai' },
    // { value: 'Salem', viewValue: 'Salem' },
    // { value: 'Vellore', viewValue: 'Vellore' },
    { value: 'Coimbatore', viewValue: 'Coimbatore' }

  ]

  constructor(
    private fb: FormBuilder,
    private ngZone: NgZone,
    private mapsAPILoader: MapsAPILoader,
    private dateAdapter: DateAdapter<Date>,
    private taxiService: TaxiService,
    public dialog: MatDialog,
    private http: HttpClient
  ) {
    this.dateAdapter.setLocale('en-GB');
  }

  ngOnInit() {
    this.bookingFormGroup = this.fb.group({
      tripType: ['Rental', Validators.required],
      name: ['', Validators.required],
      origin: ['', Validators.required],
      phone: ['', [
        Validators.required,
        Validators.pattern(/^[0]?[6789]\d{9}$/)
      ]],
      pickupDate: [new Date, Validators.required],
      pickupTime: ['', Validators.required],
      returnDate: [new Date],
      email: [''],
      rental: ['', Validators.required],

    });
  }

  get bookingFormValidation() { return this.bookingFormGroup.controls; }

  requestAride() {

    this.submitted = true;

    if (this.bookingFormGroup.invalid) {
      return;
    }

    this.routeDetails = {
      'origin': this.bookingFormGroup.get('origin').value,
      'originCityName': this.bookingFormGroup.get('origin').value,
      'totalDistance': this.bookingFormGroup.get('rental').value.split('-')[1].replace(/[^0-9.-]+/g, ""),
      'totalDuration': this.bookingFormGroup.get('rental').value.split('-')[0],
      'distance': this.bookingFormGroup.get('rental').value.split('-')[1].replace(/[^0-9.-]+/g, "")
    }

    this.rideDetails = {
      ...this.bookingFormGroup.value,
      ...this.routeDetails
    }

    let quickEnquiry = {
      ...this.rideDetails,
      ...this.settings
    }

    if (this.settings.OTP) {
      this.http.post(this.settings.quickEnquiry, quickEnquiry).subscribe(res => {
        if (res) {
          console.log('Quick enquiry sent successfully');
        }
      });
    } else {
      this.http.post(this.settings.quickEnquiry, quickEnquiry).subscribe(res => {
        if (res) {
          console.log('Quick enquiry sent successfully');
        }
      });
    }
  }


}
