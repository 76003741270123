export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyCN0-ekzt9AlumI5ty4uSQqHpyABCZrUKc",
    authDomain: "mounidroptaxi.firebaseapp.com",
    databaseURL: "https://mounidroptaxi-default-rtdb.firebaseio.com",
    projectId: "mounidroptaxi",
    storageBucket: "mounidroptaxi.appspot.com",
    messagingSenderId: "564461919683",
    appId: "1:564461919683:web:ee0b41ff2f04bf5d4a7176"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
