export const SITE_SETTINGS = {
    isCMS: true,
    isDatabaseEnable: true,
    isTelegramEnabled: false,
    isRentalFixedPrice: true,
    isSMSEnabled: false,
    OTP: false,
    siteName: 'Mouni Drop Taxi',
    phoneNo: '9962940703',
    whatsapp: '+919962940703',
    websiteAddress: 'www.mounidroptaxi.com',
    smsKey: '',
    enquiryEmail: 'mounidroptaxi@gmail.com',
    emailAPI: 'https://us-central1-calvincare-emailservice.cloudfunctions.net/sendEmail',
    smsAPI: 'https://us-central1-oceanic-glazing-309707.cloudfunctions.net/sms/api/v1/sendsms',
    tariffAPI: 'https://cdn.contentful.com/spaces/0p8akdvur1r4/environments/master/entries?access_token=cDrHldc07pZMof4-sEukQEHhoiojDCQvi4hdpuK5mYs',
    quickEnquiry: 'https://us-central1-smsapi-service.cloudfunctions.net/quickEnquiry',
    siteInfo: `Toll Gate, Permit, Hill Station Charges Extra`,
    adminEmailSubject: 'Website Confirmation Email From',
    customerEmailSubject: 'Booking Confirmation ',
    bannerColor: "#fff",
    appPassword: 'wzwuhcjbqlqkapzk',
    appEmail: 'calvincareinfotech.emailservice@gmail.com',
    tKey: '',
    tGroup: '',
    telegramAPI: '',
    contentAPI: 'https://cdn.contentful.com/spaces/0p8akdvur1r4/environments/master/entries?access_token=cDrHldc07pZMof4-sEukQEHhoiojDCQvi4hdpuK5mYs',
    logoURL:'https://www.mounidroptaxi.com/assets/logo.png',
    OTPApi:''
}



export const TIME = [
    '12:00 AM',
    '12:30 AM',
    '01:00 AM',
    '01:30 AM',
    '02:00 AM',
    '02:30 AM',
    '03:00 AM',
    '03:30 AM',
    '04:00 AM',
    '04:30 AM',
    '05:00 AM',
    '05:30 AM',
    '06:00 AM',
    '06:30 AM',
    '07:00 AM',
    '07:30 AM',
    '08:00 AM',
    '08:30 AM',
    '09:00 AM',
    '09:30 AM',
    '10:00 AM',
    '10:30 AM',
    '11:00 AM',
    '11:30 AM',
    '12:00 PM',
    '12:30 PM',
    '01:00 PM',
    '01:30 PM',
    '02:00 PM',
    '02:30 PM',
    '03:00 PM',
    '03:30 PM',
    '04:00 PM',
    '04:30 PM',
    '05:00 PM',
    '05:30 PM',
    '06:00 PM',
    '06:30 PM',
    '07:00 PM',
    '07:30 PM',
    '08:00 PM',
    '08:30 PM',
    '09:00 PM',
    '09:30 PM',
    '10:00 PM',
    '10:30 PM',
    '11:00 PM',
    '11:30 PM',
]
