<!--page start-->
<div class="page">
    <!--header start-->
    <header id="masthead" class="header ttm-header-style-03">
        <!-- topbar -->
        <div class="top_bar ttm-bgcolor-darkgrey clearfix">
            <div class="container">
                <div class="row no-gutters">
                    <div class="col-xl-12 d-flex flex-row align-items-center">
                        <div class="top_bar_contact_item">
                            <div class="top_bar_icon"><i class="ti ti-email"></i></div><a
                                href="mailto:{{settings.enquiryEmail}}">{{settings.enquiryEmail}}</a>
                        </div>
                        <div class="top_bar_contact_item">
                            <div class="top_bar_icon"><i class="ti ti-location-pin"></i></div>Chennai, Tamilnadu
                        </div>
                        <div class="top_bar_contact_item top_bar_social ml-auto p-0">
                            <ul class="social-icons list-inline">
                                <li><a class="tooltip-top" href="#" rel="noopener" aria-label="facebook"
                                        data-tooltip="Facebook"><i class="fa fa-facebook"></i></a></li>
                                <li><a class="tooltip-top" href="#" rel="noopener" aria-label="twitter"
                                        data-tooltip="Twitter"><i class="fa fa-twitter"></i></a></li>
                                <li><a class="tooltip-top" href="#" rel="noopener" aria-label="flickr"
                                        data-tooltip="Flickr"><i class="fa fa-flickr"></i></a></li>
                                <li><a class="tooltip-top" href="#" rel="noopener" aria-label="linkedin"
                                        data-tooltip="Linkedin"><i class="fa fa-linkedin"></i></a></li>
                            </ul>
                        </div>
                        <div class="top_bar_contact_item">
                            <div class="header_search">
                                <a href="#" class="btn-default search_btn"><i class="fa fa-search"></i></a>
                                <div class="header_search_content">
                                    <div class="header_search_content_inner">
                                        <a href="#" class="close_btn"><i class="ti ti-close"></i></a>
                                        <form id="searchbox" method="get" action="#">
                                            <input class="search_query" type="text" id="search_query_top" name="s"
                                                placeholder="Enter Keyword" value="">
                                            <button type="submit" class="btn close-search"><i
                                                    class="ti ti-search"></i></button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- topbar end -->
        <!-- site-header-menu -->
        <div id="site-header-menu" class="site-header-menu ttm-bgcolor-white">
            <div class="site-header-menu-inner ttm-stickable-header">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <!--site-navigation -->
                            <div class="site-navigation d-flex flex-row align-items-center justify-content-between">
                                <!-- site-branding -->
                                <div class="site-branding ">
                                    <a class="home-link" href="index.html" title="Fablio" rel="home">
                                        <img id="logo-img" width="280" class="img-fluid auto_size" src="assets/logo.png"
                                            alt="logo-img">
                                    </a>
                                </div>
                                <!-- site-branding end -->
                                <div class="d-flex flex-row m-auto">
                                    <div class="btn-show-menu-mobile menubar menubar--squeeze">
                                        <span class="menubar-box">
                                            <span class="menubar-inner"></span>
                                        </span>
                                    </div>
                                    <!-- menu -->
                                    <nav class="main-menu menu-mobile" id="menu">
                                        <ul class="menu">
                                            <li class="mega-menu-item">
                                                <a routerLink="/">Home</a>
                                            </li>
                                            <li class="mega-menu-item">
                                                <a routerLink="/about">About us</a>
                                            </li>
                                            <li class="mega-menu-item">
                                                <a routerLink="/city">Outstation Routes</a>
                                            </li>
                                            <li class="mega-menu-item">
                                                <a routerLink="/tariff">Tariff</a>
                                            </li>

                                            <li class="mega-menu-item">
                                                <a routerLink="/contact">Contact us</a>
                                            </li>
                                        </ul>
                                    </nav>
                                    <!-- menu end -->
                                </div>
                                <div class="widget_info d-flex flex-row align-items-center">
                                    <div class="widget_icon ttm-textcolor-skincolor"><i class="flaticon-chat"></i></div>
                                    <div class="widget_content">
                                        <h3 class="widget_title"><a
                                                href="tel:{{settings.phoneNo}}">{{settings.phoneNo}}</a></h3>
                                        <p class="widget_desc">Have any Questions?</p>
                                    </div>
                                </div>
                            </div>
                            <!-- site-navigation end-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- site-header-menu end-->

        <nav class="navbar navbar-expand-lg navbar-light">
            <a class="navbar-brand" routerLink="/">
                <img id="logo-img" width="280" class="img-fluid auto_size" src="assets/logo.png" alt="logo-img">
            </a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="menubar-box"><span class="menubar-inner"></span></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav">

                    <li class="nav-item active">
                        <a class="nav-link" routerLink="/">Home</a>
                    </li>
                    <li class="nav-item active">
                        <a class="nav-link" routerLink="/about">About us</a>
                    </li>
                    <li class="nav-item active">
                        <a class="nav-link" routerLink="/city">Outstation Routes</a>
                    </li>
                    <li class="nav-item active">
                        <a class="nav-link" routerLink="/tariff">Tariff</a>
                    </li>

                    <li class="nav-item active">
                        <a class="nav-link" routerLink="/contact">Contact us</a>
                    </li>

                </ul>
            </div>
        </nav>


    </header>
    <!--header end-->


    <!-- Banner -->
    <div class="banner_slider_wrapper">
        <div class="banner_slider banner_slider_3">
            <div class="slide">
                <div class="slide_img" style="background-image: url(assets/bg.jpeg);"></div>
                <div class="slide__content">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div
                                    class="slide__content--headings ttm-textcolor-white text-center margin_bottom100 res-991-margin_bottom0">
                                    <span data-animation="fadeInDown" class="highlight_text">Best Outstation Drop Taxi
                                        Provider</span>

                                    <h2 data-animation="fadeInDown"> Ride out of town with <strong>
                                            {{settings.siteName}}</strong></h2>
                                    <div class="d-inline-block margin_top30 res-767-margin_top20"
                                        data-animation="fadeInUp" data-delay="1.4">
                                        <p>
                                            Now book cabs online at best price from {{settings.siteName}}. Get all types
                                            of cheap taxi booking services at lowest fare. Find best car booking deals
                                        </p>

                                    </div>
                                </div>
                              
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Banner end-->


    <!--site-main start-->
    <div class="site-main" id="booking">

       <app-booking-form></app-booking-form>

        <!--padding_zero-section-->
        <section class="ttm-row padding_zero-section clearfix">
            <div class="container">
                <!-- row -->
                <div class="row">
                    <div class="col-lg-12">
                        <div class="ttm-bg ttm-col-bgcolor-yes ttm-bgcolor-white ttm-bg mt-5 p-3 box-shadow">
                            <div class="ttm-col-wrapper-bg-layer ttm-bg-layer">
                                <div class="ttm-col-wrapper-bg-layer-inner"></div>
                            </div>
                            <div class="layer-content">
                                <div class="row no-gutters">
                                    <div class="col-lg-7">
                                        <div class="res-991-padding_bottom40">
                                            <!-- section title -->
                                            <div class="section-title">
                                                <div class="title-header">
                                                    <h3>ABOUT US</h3>
                                                    <h2 class="title">Best Inter-city and <b>Outstation </b>drop taxi
                                                        providers</h2>
                                                </div>
                                                <div class="title-desc">
                                                    <p>{{settings.siteName}} is an Inter-city taxi booking
                                                        facilitator.We started this venture out of a personal pain point
                                                        of having to pay two way charges for a one way drop trip.</p>
                                                </div>
                                            </div>
                                            <!-- section title end -->
                                            <div class="ttm-horizontal_sep width-100 margin_top20 margin_bottom30">
                                            </div>
                                            <div class="row ttm-vertical_sep">
                                                <div class="col-md-6 col-sm-6">
                                                    <!--featured-icon-box-->
                                                    <div class="featured-icon-box icon-align-top-content style1 pt-0">
                                                        <div class="featured-icon padding_top15">
                                                            <div
                                                                class="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-md">
                                                                <img width="64px" src="assets/wall.avif" alt=""
                                                                    srcset="">
                                                            </div>
                                                        </div>
                                                        <div class="featured-content">
                                                            <div class="featured-title">
                                                                <h3>Always Genuine</h3>
                                                            </div>
                                                            <div class="featured-desc">
                                                                <p>We guarantee you will always provide genuine and safe
                                                                    outstation travels</p>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <!-- featured-icon-box end-->
                                                </div>
                                                <div class="col-md-6 col-sm-6">
                                                    <!--featured-icon-box-->
                                                    <div class="featured-icon-box icon-align-top-content style1 pt-0">
                                                        <div class="featured-icon padding_top15">
                                                            <div
                                                                class="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-md">
                                                                <img width="64px" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRTENziMFPUC2h0AFg-Xi-485Koh_QkF8h9wAVy_pDMF0GCv9nUOH7YfCEgM4PQPxpA5zk&usqp=CAU" alt=""
                                                                    srcset="">
                                                            </div>
                                                        </div>
                                                        <div class="featured-content">
                                                            <div class="featured-title">
                                                                <h3>Secure and Safer Rides
                                                                </h3>
                                                            </div>
                                                            <div class="featured-desc">
                                                                <p>Verified drivers, an emergency alert button, and live
                                                                    ride tracking are some of the features that we have
                                                                    in place to ensure you a safe travel experience.
                                                                </p>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <!-- featured-icon-box end-->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-5">
                                        <div class="ttm_single_image-wrapper text-lg-right">
                                            <img class="img-fluid auto_size" width="381" height="531"
                                                src="assets/car-img.png" alt="single-05">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- row end -->
            </div>
        </section>
        <!--padding_zero-section end-->


        <!--about-section-->
        <section class="ttm-row about-section clearfix bg-blue">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="ttm_single_image-wrapper imagestyle-three">
                            <div class="ttm_single_image_text ttm-bgcolor-skincolor">25 Years Of Experience!</div>
                            <img class="img-fluid auto_size" src="assets/beautiful-african-girl-catching-car_176420-5408.jpg" alt="single-06"
                                height="615" width="542">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-xs-12">
                        <div class="res-991-padding_top40">
                            <!-- section title -->
                            <div class="section-title">
                                <div class="title-header">
                                    <h3>{{settings.siteName | uppercase}}</h3>
                                    <h2 class="title">
                                        A cab for every occasion with
                                        <b>{{settings.siteName}}</b>
                                    </h2>
                                </div>
                                <div class="title-desc">
                                    <p>
                                        Ride out of town at affordable one-way and round-trip fares with
                                        {{settings.siteName}} intercity travel service. Choose from a range of AC cabs
                                        driven by top partners, available in advance. We have you covered across India
                                        with presence in 30+ cities
                                        with over 500 one way routes.
                                    </p>
                                </div>
                            </div>
                            <!-- section title end -->
                            <div class="row">
                                <div class="col d-inline">
                                    <!--featured-icon-box-->
                                    <div class="featured-icon-box icon-align-before-content">
                                        <div class="featured-icon">
                                            <div
                                                class="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                                <img src="assets/salary.png" alt="" srcset="">
                                            </div>
                                        </div>
                                        <div class="featured-content">
                                            <div class="featured-title">
                                                <h3>
                                                    Cabs at affordable Rates
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- featured-icon-box end-->
                                </div>
                                <div class="col d-inline">
                                    <!--featured-icon-box-->
                                    <div class="featured-icon-box icon-align-before-content">
                                        <div class="featured-icon">
                                            <div
                                                class="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                                <img src="assets/customer-service.png" alt="" srcset="">
                                            </div>
                                        </div>
                                        <div class="featured-content">
                                            <div class="featured-title">
                                                <h3>24/7 Support</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- featured-icon-box end-->
                                </div>
                            </div>
                            <div class="ttm-horizontal_sep width-100 margin_top10 margin_bottom20"></div>
                            <div class="row">
                                <div class="col">
                                    <!--featured-icon-box-->
                                    <div class="featured-icon-box icon-align-before-content">
                                        <div class="featured-icon">
                                            <div
                                                class="ttm-icon ttm-icon_element-style-rounded ttm-icon_element-fill ttm-icon_element-color-grey ttm-icon_element-size-md">
                                                <i class="flaticon flaticon-yarn-1 fs-30 ttm-textcolor-skincolor"></i>
                                            </div>
                                        </div>
                                        <div class="featured-content">
                                            <div class="featured-desc">
                                                <p>
                                                    From Sedans and SUVs for special occasions, we have cabs to suit
                                                    every pocket
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- featured-icon-box end-->
                                </div>
                            </div>
                            <div class="d-inline-block margin_top25 res-767-margin_top20">
                                <a class="ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-btn-style-fill ttm-btn-color-skincolor margin_right20 margin_top15 black"
                                    href="#booking">Book Now</a>
                                <a class="ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-btn-style-fill ttm-btn-color-skincolor margin_right20 margin_top15 black call-btn"
                                    href="tel:{{settings.phoneNo}}"><i
                                        class="fa fa-phone fs-26 ttm-textcolor-skincolor"></i>Call Us</a>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- row end -->
            </div>
        </section>
        <!--about-section end-->


        <!--services-section-->
        <section class="ttm-row services-section ttm-bgcolor-grey clearfix">
            <div class="container">
                <!-- row -->
                <div class="row">
                    <div class="col-lg-12">
                        <!-- section title -->
                        <div class="section-title title-style-center_text">
                            <div class="title-header">
                                <h3>POPULAR CHENNAI ROUTES </h3>
                                <h2 class="title"><b>Chennai </b> Outstation Drop Taxi Routes</h2>
                            </div>
                        </div>
                        <!-- section title end -->
                    </div>
                </div>

                <div class="row slick_slider">
                    <div *ngFor="let place of places[0].routes" class="col-lg-4">
                        <div class="featured-imagebox featured-imagebox-post style1">
                            <div class="featured-thumbnail">

                                <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="5">
                                    <agm-direction [origin]="place.origin" [destination]="place.destination">
                                    </agm-direction>
                                </agm-map>

                            </div>
                            <div class="featured-content">
                                <!-- ttm-box-post-date -->
                                <div class="ttm-box-post-date">
                                    <span class="ttm-entry-date">
                                        <time class="entry-date" datetime="2021-02-28T04:15:42+00:00">₹ {{
                                            place.estimation}}<span
                                                class="entry-month entry-year">{{place.distance}}</span></time>
                                    </span>
                                </div>
                                <div class="post-meta">
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>

                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>

                                    <span class="ttm-meta-line byline sp">ONE WAY DROP TAXI</span>
                                </div>
                                <!-- post-meta end -->
                                <div class="featured-title">
                                    <h3><a routerLink="/city/{{place.origin | lowercase}}-to-{{place.destination | lowercase}}-cabs"
                                            class="stretched-link"> {{place.origin}} to {{place.destination}}</a></h3>
                                </div>
                                <div class="featured-desc">
                                    <p>
                                        Book {{place.origin}} to {{place.destination}} taxi Service for One Way cab &
                                        Round Trip at affordable Taxi Fare at {{settings.siteName}}. Our expert Drivers
                                        will make your trip memorable
                                    </p>
                                    <ul class="wo-vehiclesinfo__list">
                                        <li><span>Car Type<em>Sedan</em></span></li>
                                        <li><span>Total Distance<em>{{place.distance}}</em></span></li>
                                        <li><span>Total Duration <em>{{place.duration}} </em></span></li>
                                        <li><span>Rate Per KM<em>₹{{place.ratePerKM}}</em></span></li>
                                    </ul>
                                </div>
                                <div class="col-lg-12 text-center">
                                    <a class="ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-btn-style-fill ttm-btn-color-skincolor margin_top20 res-991-margin_right15"
                                        routerLink="/city/{{place.origin | lowercase}}-to-{{place.destination | lowercase}}-cabs">BOOK
                                        NOW</a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


                <!-- row end -->
            </div>

        </section>
        <!--services-section end -->

        <ng-container *ngFor="let route of  places">
            <section class="ttm-row services-section ttm-bgcolor-grey clearfix">
                <div class="container">
                    <!-- row -->
                    <div class="row">
                        <div class="col-lg-12">
                            <!-- section title -->
                            <div class="section-title title-style-center_text">
                                <div class="title-header">
                                    <h3>POPULAR {{route.city}} ROUTES </h3>
                                    <h2 class="title"><b>{{route.city}} </b> Outstation Drop Taxi Routes</h2>
                                </div>
                            </div>
                            <!-- section title end -->
                        </div>
                    </div>

                    <owl-carousel-o [options]="customOptions">
                        <ng-template *ngFor="let place of  route.routes" carouselSlide>
                            <div class="wrap">
                                <div class="featured-imagebox featured-imagebox-post style1">
                                    <div class="featured-thumbnail">

                                        <a routerLink="/city/{{place.origin | lowercase}}-to-{{place.destination | lowercase}}-cabs"
                                            class="muse-animation">
                                            <img src="{{place.image}}" class="img-fluid w-100" alt="Travel">
                                        </a>
                                    </div>
                                    <div class="featured-content">
                                        <!-- ttm-box-post-date -->
                                        <div class="ttm-box-post-date">
                                            <span class="ttm-entry-date">
                                                <time class="entry-date" datetime="2021-02-28T04:15:42+00:00">₹
                                                    {{place.estimation}}<span
                                                        class="entry-month entry-year">{{place.distance}}</span></time>
                                            </span>
                                        </div>
                                        <div class="post-meta">
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>

                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>

                                            <span class="ttm-meta-line byline sp">ONE WAY DROP TAXI</span>
                                        </div>
                                        <!-- post-meta end -->
                                        <div class="featured-title">
                                            <h3><a routerLink="/city/{{place.origin | lowercase}}-to-{{place.destination | lowercase}}-cabs"
                                                    class="stretched-link"> {{place.origin}} to
                                                    {{place.destination}}</a></h3>
                                        </div>
                                        <div class="featured-desc">
                                            <p>
                                                Book {{place.origin}} to {{place.destination}} taxi Service for One Way
                                                cab & Round Trip at affordable Taxi Fare at {{settings.siteName}}. Our
                                                expert Drivers will make your trip memorable
                                            </p>
                                            <ul class="wo-vehiclesinfo__list">
                                                <li><span>Car Type<em>Sedan</em></span></li>
                                                <li><span>Total Distance<em>{{place.distance}}</em></span></li>
                                                <li><span>Total Duration <em>{{place.duration}} </em></span></li>
                                                <li><span>Rate Per KM<em>₹18.00</em></span></li>
                                            </ul>
                                        </div>
                                        <div class="col-lg-12 text-center">
                                            <a class="ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-btn-style-fill ttm-btn-color-skincolor margin_top20 res-991-margin_right15"
                                                routerLink="/city/{{place.origin | lowercase}}-to-{{place.destination | lowercase}}-cabs">BOOK
                                                NOW</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </owl-carousel-o>




                    <!-- row end -->
                </div>

            </section>
        </ng-container>





        <!--cta-section-->
        <section class="ttm-row cta-section ttm-bgimage-yes bg-img3 ttm-bg ttm-bgcolor-darkgrey clearfix">
            <div class="ttm-row-wrapper-bg-layer ttm-bg-layer"></div>
            <div class="container">
                <!-- row -->
                <div class="row">
                    <div class="col-lg-9">
                        <h2>One Way & Round Trip Outstation Taxi @ One Way Fare</h2>
                        <p>There Is Always A Tariff For Taxis With Us That Suits Your Budget At {{settings.siteName}}.
                            Enjoy Your Comfortable Trip With {{settings.siteName}}. 24/7 Customer service. Door to door
                            step service. Brands: Door to door step service,
                            Drop Taxi, Outstation taxi.
                        </p>
                    </div>
                    <div class="col-lg-3">
                        <div class="text-lg-right">
                            <a class="ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-btn-style-fill ttm-btn-color-skincolor margin_top20 res-991-margin_right15"
                                href="#booking">Book Now</a>
                            <a class="ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-btn-style-border ttm-btn-color-white margin_top20"
                                href="tel:{{settings.phoneNo}}">Call Us</a>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="padding_top70 res-991-padding_top0"></div>
                    </div>
                </div>
                <!-- row end -->
            </div>
        </section>
        <!--cta-section end-->


        <!--padding_zero-section-->
        <section class="ttm-row padding_zero-section bg-layer-equal-height clearfix">
            <div class="container">
                <div class="row no-gutters">
                    <div class="col-lg-12">
                        <div class="row">
                            <div class="col-lg-6 col-md-12">
                                <div
                                    class="ttm-bg ttm-col-bgcolor-yes padding_top10 ttm-bgcolor-skincolor ttm-left-span z-index-2 h-auto mt_10">
                                    <div class="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                                    <div class="layer-content">
                                    </div>
                                </div>
                                <!-- col-img-img-five -->
                                <div class="ttm-bg ttm-col-bgimage-yes col-bg-img-five ttm-left-span z-index-2">
                                    <div class="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                                    <div class="layer-content">
                                    </div>
                                </div>
                                <!-- col-img-bg-img-five end-->
                                <img class="img-fluid ttm-equal-height-image w-100"
                                    src="assets/images/bg-image/bg-face.jpeg" alt="bg-image">
                            </div>
                            <div class="col-lg-6">
                                <div
                                    class="ttm-bg ttm-col-bgimage-yes col-bg-img-six ttm-bg ttm-right-span spacing-8 z-index-2">
                                    <div class="ttm-col-wrapper-bg-layer ttm-bg-layer">
                                        <div class="ttm-col-wrapper-bg-layer-inner"></div>
                                    </div>
                                    <div class="layer-content">
                                        <!-- section title -->
                                        <div class="section-title">
                                            <div class="title-header">
                                                <h3>WHY CHOOSE US</h3>
                                                <h2 class="title">The best way to travel to your
                                                    <b>destination</b>
                                                </h2>
                                            </div>
                                            <div class="title-desc">
                                                <p>{{settings.siteName}} indeed saves upto 40% to customers for drop
                                                    trips.Roundtrips also available at competitive rates.

                                                </p>
                                            </div>
                                        </div>
                                        <!-- section title end -->
                                        <div class="row ttm-vertical_sep">
                                            <div class="col-md-6 col-sm-6">
                                                <!--featured-icon-box-->
                                                <div
                                                    class="featured-icon-box icon-align-before-title style2 padding_right10 margin_bottom30 res-575-margin_bottom0">
                                                    <div class="featured-icon">
                                                        <div
                                                            class="ttm-icon ttm-icon_element-fill ttm-icon_element-style-rounded ttm-icon_element-color-skincolor ttm-icon_element-size-xs">
                                                            <div class="fa fa-check"></div>
                                                        </div>
                                                    </div>
                                                    <div class="featured-title">
                                                        <h3>Cabs for Every Pocket</h3>
                                                    </div>
                                                    <div class="featured-content">
                                                        <div class="featured-desc">
                                                            <p>From Sedans and SUVs for special occasions, we have cabs
                                                                to suit every pocket</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- featured-icon-box end-->
                                            </div>
                                            <div class="col-md-6 col-sm-6">
                                                <!--featured-icon-box-->
                                                <div
                                                    class="featured-icon-box icon-align-before-title style2 padding_left15 res-991-padding_left0 margin_bottom30 res-575-margin_bottom0">
                                                    <div class="featured-icon">
                                                        <div
                                                            class="ttm-icon ttm-icon_element-fill ttm-icon_element-style-rounded ttm-icon_element-color-skincolor ttm-icon_element-size-xs">
                                                            <div class="fa fa-check"></div>
                                                        </div>
                                                    </div>
                                                    <div class="featured-title">
                                                        <h3>Secure and Safer Rides</h3>
                                                    </div>
                                                    <div class="featured-content">
                                                        <div class="featured-desc">
                                                            <p>Verified drivers, an emergency alert button, and live
                                                                ride tracking are some of the features that we have in
                                                                place to ensure you a safe travel experience.

                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- featured-icon-box end-->
                                            </div>
                                        </div>
                                        <div class="ttm-horizontal_sep width-100 sm-hide"></div>
                                        <div class="row ttm-vertical_sep">
                                            <div class="col-md-6 col-sm-6">
                                                <!--featured-icon-box-->
                                                <div
                                                    class="featured-icon-box icon-align-before-title style2 padding_right10 margin_top30 res-575-margin_tpo0">
                                                    <div class="featured-icon">
                                                        <div
                                                            class="ttm-icon ttm-icon_element-fill ttm-icon_element-style-rounded ttm-icon_element-color-skincolor ttm-icon_element-size-xs">
                                                            <div class="fa fa-check"></div>
                                                        </div>
                                                    </div>
                                                    <div class="featured-title">
                                                        <h3>24 / 7 Car Support</h3>
                                                    </div>
                                                    <div class="featured-content">
                                                        <div class="featured-desc">
                                                            <p>We Offer 24x7 Pickup Up And Drop Facility In Tamil Nadu
                                                                To Facilitate The Customers Emergency Needs.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- featured-icon-box end-->
                                            </div>
                                            <div class="col-md-6 col-sm-6">
                                                <!--featured-icon-box-->
                                                <div
                                                    class="featured-icon-box icon-align-before-title style2 padding_left15 res-991-padding_left0 margin_top30 res-575-margin_tpo0">
                                                    <div class="featured-icon">
                                                        <div
                                                            class="ttm-icon ttm-icon_element-fill ttm-icon_element-style-rounded ttm-icon_element-color-skincolor ttm-icon_element-size-xs">
                                                            <div class="fa fa-check"></div>
                                                        </div>
                                                    </div>
                                                    <div class="featured-title">
                                                        <h3>Top Rated Driver - Partner</h3>
                                                    </div>
                                                    <div class="featured-content">
                                                        <div class="featured-desc">
                                                            <p>All Our Driver trainer To Delivery The Best Experience.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- featured-icon-box end-->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- row end -->
                    </div>
                </div>
            </div>
        </section>
        <!--padding_bottom_zero-section end-->

    </div>
    <!--site-main end-->


    <!--footer start-->
    <footer class="footer widget-footer ttm-bgcolor-darkgrey ttm-textcolor-white clearfix">
        <div class="first-footer">
            <div class="container">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-9 widget-area">
                        <div class="widget widget_text clearfix">
                            <div class="d-sm-flex align-items-center">
                                <div class="textwidget widget-text">
                                    <p>Mouni Drop Taxiis an Inter-city taxi booking facilitator.We started this
                                        venture out of a personal pain point of having to pay two way charges for a one
                                        way drop trip.

                                    </p>
                                    <ul>
                                        <li>
                                            <a
                                                href="https://play.google.com/store/apps/details?id=com.outstationrentalcar.calvincare">
                                                <img src="assets/google-play.png" alt="" srcset="">
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 widget-area">
                        <div class="widget widget_social clearfix">
                            <div class="social-icons text-lg-right">
                                <ul class="social-icons list-inline">
                                    <li><a class="tooltip-top" href="#" rel="noopener" aria-label="facebook"
                                            data-tooltip="Facebook">
                                            <img src="assets/icons8-facebook-48.png" alt="" srcset="">

                                        </a></li>
                                    <li><a class="tooltip-top" href="#" rel="noopener" aria-label="twitter"
                                            data-tooltip="Twitter">
                                            <img src="/assets/icons8-twitter-64.png" alt="" srcset="">

                                        </a></li>
                                    <li><a class="tooltip-top" href="#" rel="noopener" aria-label="instagram"
                                            data-tooltip="instagram">
                                            <img src="assets/icons8-instagram-64.png" alt="" srcset="">

                                        </a></li>
                                    <li><a class="tooltip-top" href="#" rel="noopener" aria-label="linkedin"
                                            data-tooltip="Linkedin">
                                            <img src="assets/linkedin.png" alt="" srcset="">

                                        </a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="second-footer">
            <div class="container">
                <div class="row">

                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 widget-area">
                        <div class="widget widget_nav_menu clearfix">
                            <h3 class="widget-title">Chennai One Way Drop Taxi</h3>
                            <ul id="menu-footer-quick-links" class="menu">
                                <li>
                                    <a href="/city/chennai-to-madurai-outstation-cabs">Chennai to Madurai cabs</a>
                                </li>
                                <li>
                                    <a href="/city/chennai-to-pondicherry-outstation-cabs">Chennai to Pondicherry cabs
                                    </a>
                                </li>
                                <li>
                                    <a href="/city/chennai-to-bangalore-outstation-cabs">Chennai to Bangalore cabs
                                    </a>
                                </li>
                                <li>
                                    <a href="/city/chennai-to-vellore-outstation-cabs">Chennai to Vellore cabs
                                    </a>
                                </li>
                                <li>
                                    <a href="/city/chennai-to-thanjavur-outstation-cabs">Chennai to Thanjavur cabs
                                    </a>
                                </li>
                                <li>
                                    <a href="/city/chennai-to-coimbatore-outstation-cabs">Chennai to Coimbatore cabs
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 widget-area">
                        <div class="widget widget_nav_menu clearfix">
                            <h3 class="widget-title">Coimbatore One Way Drop Taxi</h3>
                            <ul id="menu-footer-quick-links" class="menu">
                                <li>
                                    <a href="/city/coimbatore-to-madurai-outstation-cabs">Coimbatore to Madurai</a>
                                </li>
                                <li>
                                    <a href="/city/coimbatore-to-pondicherry-outstation-cabs">Coimbatore to Pondicherry
                                    </a>
                                </li>
                                <li>
                                    <a href="/city/coimbatore-to-bangalore-outstation-cabs">Coimbatore to Bangalore
                                    </a>
                                </li>
                                <li>
                                    <a href="/city/coimbatore-to-vellore-outstation-cabs">Coimbatore to Vellore
                                    </a>
                                </li>
                                <li>
                                    <a href="/city/coimbatore-to-thanjavur-outstation-cabs">Coimbatore to Thanjavur
                                    </a>
                                </li>
                                <li>
                                    <a href="/city/coimbatore-to-chennai-outstation-cabs">Coimbatore to Chennai
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 widget-area">
                        <div class="widget widget_nav_menu clearfix">
                            <h3 class="widget-title">Bangalore One Way Drop Taxi</h3>
                            <ul id="menu-footer-quick-links" class="menu">
                                <li>
                                    <a href="/city/bangalore-to-madurai-outstation-cabs">Bangalore to Madurai cabs</a>
                                </li>
                                <li>
                                    <a href="/city/bangalore-to-pondicherry-outstation-cabs">Bangalore to Pondicherry
                                        cabs
                                    </a>
                                </li>
                                <li>
                                    <a href="/city/bangalore-to-bangalore-outstation-cabs">Bangalore to Bangalore cabs
                                    </a>
                                </li>
                                <li>
                                    <a href="/city/bangalore-to-vellore-outstation-cabs">Bangalore to Vellore cabs
                                    </a>
                                </li>
                                <li>
                                    <a href="/city/bangalore-to-thanjavur-outstation-cabs">Bangalore to Thanjavur cabs
                                    </a>
                                </li>
                                <li>
                                    <a href="/city/bangalore-to-coimbatore-outstation-cabs">Bangalore to Coimbatore cabs
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 widget-area">
                        <div class="widget widget-latest-tweets clearfix">
                            <h3 class="widget-title">Contact Us</h3>
                            <ul class="widget_contact_wrapper">
                                <li><i class="ttm-textcolor-skincolor fa fa-map-marker"></i>3/42 ganthi nagar 2nd Street palavakkam Chennai 600041 
                                </li>
                                <li><i class="ttm-textcolor-skincolor fa fa-phone"></i>{{settings.phoneNo}}</li>
                                <li><i class="ttm-textcolor-skincolor fa fa-envelope-o"></i><a
                                        href="{{settings.enquiryEmail}}">{{settings.enquiryEmail}}</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottom-footer-text copyright">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="text-center">
                            <span class="cpy-text">Copyright © 2024 <a
                                    class="ttm-textcolor-skincolor font-weight-500">{{settings.siteName}} </a> All
                                rights reserved.</span>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    <!--footer end-->

    <!--back-to-top start-->
    <!-- <a id="totop" href="#top">
        <i class="fa fa-angle-up"></i>
    </a> -->
    <!--back-to-top end-->

</div>
<!-- page end -->