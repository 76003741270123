<form [formGroup]="bookingFormGroup">

    <div class="wid100 fl">
        <div class="pd10_n mgt13 fl">
            <mat-error *ngIf="submitted && bookingFormValidation['name'].errors">
                Name is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="submitted && bookingFormValidation['phone'].errors">
                Mobile number is <strong>required</strong>
            </mat-error>
           
            <div class="row">
                <div class="col">
                    <p class="inpttl">Enter Name</p>
                    <input type="text" placeholder="Full name" formControlName="name" class="hl-input">
                </div>
                <div class="col">
                    <p class="inpttl">Mobile Number</p>
                    <input class="hl-input" type="number" formControlName="phone" placeholder="Mobile Number" required>
                </div>

            </div>

        </div>
    </div>

    <div class="clr"></div>

    <div class="clr"></div>

    <div class="pd10_n mgt13">
        <div class="wid10 ng-scope" ng-if="SearchDetail.TravelType!='hourly'">
            <div class="w_circle"></div>
            <i class="des_icn"></i>
        </div>
        <div class="wid87">
            <div class="in_box3 m_rel">
                <p class="inpttl">Pick-up location</p>
                <mat-select  class="form-control time-select" formControlName="origin" placeholder="Select City">
                    <mat-option *ngFor="let city of rentalCity" [value]="city.value">
                        {{city.value}}
                    </mat-option>
                </mat-select>
            </div>
           
        </div>



    </div>
    <div class="wid100 fl date-wrap">
        <div class="pd10_n fl mgt13">
            <div class="row">
                <div class="col">
                    <p class="inpttl">Pick-up Date</p>
                    <input formControlName="pickupDate" placeholder="Pickup date"
                        formControlName="pickupDate" [min]="minDate" (click)="picker.open()" [matDatepicker]="picker"
                        readonly class="hl-input2">
                    <mat-datepicker #picker></mat-datepicker>
                </div>
                <div class="col">
                    <p class="inpttl">Select Time</p>
                    <mat-select class="time-select" formControlName="pickupTime" placeholder="Time">
                        <mat-option *ngFor="let time of times" [value]="time">
                            {{time}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="submitted && bookingFormValidation['pickupTime']?.errors">
                        Pickup Time is <strong>required</strong>
                    </mat-error>
                </div>

            </div>

        </div>
    </div>


    <div class="clr"></div>

    <div class="wid100 fl">
        <div class="pd10_n fl mgt13">
            <div class="row">
                <div class="col">
                    <p class="inpttl">Rent For</p>
                    <mat-select class="form-control time-select" formControlName="rental" placeholder="Select Hour">
                        <mat-option *ngFor="let pkg of rentalPkg" [value]="pkg.value">
                            {{pkg.viewValue}}
                        </mat-option>
                    </mat-select>
                </div>
            </div>

        </div>
    </div>


    <div class="clr"></div>


    <div class="clr"></div>
    <input name="" value="Search" style="-webkit-appearance: none;" type="button" class="fs_btn"
        (click)="requestAride()">
</form>